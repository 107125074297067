/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // Steps - accordion
        $('.arrow-down').click(function() {
            $(this).toggleClass('active');
            $(this).parent('.title-box').toggleClass('active');
            $(this).parent('.title-box').prev('.num-box').toggleClass('active');
        });
        // END - Steps - accordion

        // Banner box
        $('.home-banner--bottom-box').mouseenter(function() {
            $(this).addClass('z-index-higher').delay(300).queue(function(){
                $(this).removeClass('z-index-higher').dequeue();
            });
        });
        $('.home-banner--bottom-box').mouseleave(function() {
            $(this).addClass('z-index-high').delay(300).queue(function(){
                $(this).removeClass('z-index-high').dequeue();
            });
        });

        // END - Banner box

        // Quote slider
        $('.slider-button').click(function() {
            if ($(this).hasClass('active')) {
                // do nothing
            } else {
                $('.slider-button.active').removeClass('active');
                $(this).addClass('active');
                $('.single-quote.active').removeClass('active').addClass('temp').delay(200).queue(function(){
                    $(this).removeClass('temp').dequeue();
                });
                if ($(this).is('#q-btn-1')) {
                    $('#quote-1').addClass('active');
                } else if ($(this).is('#q-btn-2')) {
                    $('#quote-2').addClass('active');
                } else if ($(this).is('#q-btn-3')) {
                    $('#quote-3').addClass('active');
                }
            }
        });
        // END - Quote slider

        // Hide header on scroll
        var didScroll,
            lastScrollTop = 0,
            delta = 5,
            navbarHeight = $('header').outerHeight();

        $(window).scroll(function (event) {
          didScroll = true;
        });

        setInterval(function () {
          if (didScroll) {
            hasScrolled();
            didScroll = false;
          }
        }, 250);

        function hasScrolled() {
          var st = $(this).scrollTop();

          // Make sure they scroll more than delta
          if (Math.abs(lastScrollTop - st) <= delta)
            return;

          // If they scrolled down and are past the navbar, add class .nav-up.
          // This is necessary so you never see what is "behind" the navbar.
          if (st + $(window).height() > $(document).height() - 100) {
            // Scroll to bottom
            $('header').removeClass('nav-up');

          } else if (st > lastScrollTop && st > navbarHeight) {
            // Scroll Down
            $('header').addClass('nav-up');

          } else if (st < 200) {
            //Scroll to top
            $('header').removeClass('nav-up');

          } else if (st + $(window).height() < $(document).height()) {
            // Scroll Up
            $('header').removeClass('nav-up');

          }

          lastScrollTop = st;
        }
        // END - Hide header on scroll

        // Mobile menu
        $('.mobile-menu').click(function() {
            $('.menu-closed, .menu-opened').toggleClass('active');
            $('.mobile-nav').toggleClass('active');
        });
        // END - Mobile menu

        // Mailchimp newsletter
        $('.pfb-signup-submission').submit(function(event) {
            event.preventDefault();

            // Get data from form and store it
            var pfbSignupEMAIL = $('.pfb-signup-box-email').val();

            // Create JSON variable of retreived data
            var pfbSignupData = {
                'email': pfbSignupEMAIL
            };

            // Send data to PHP script via .ajax() of jQuery
            $.ajax({
                type: 'POST',
                dataType: 'json',
                url: 'https://trgovina.zito.si/wp-content/themes/zito-peki/mailchimp-signup.php',
                data: pfbSignupData,
                success: function (results) {
                    $('.pfb-signup-result').html('<p>Uspešno ste se prijavili na naše novice. Hvala in na prijetno branje.</p>');
                    console.log(results);
                },
                error: function (results) {
                    $('.pfb-signup-result').html('<p>Prijava žal ni bila uspešna. Poskusite kasneje.</p>');
                    console.log(results);
                }
            });
        });
        // END - Mailchimp newsletter

        // Social share popup
        $('.fb-share').click(function(e) {
          e.preventDefault();
          window.open($(this).attr('href'), 'fbShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
          return false;
        });

        $('.twitter-share').click(function(e) {
          e.preventDefault();
          window.open($(this).attr('href'), 'twitterShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
          return false;
        });
        // END - Social share popup

        // Add required on input
        $('input[aria-required="true"], textarea[aria-required="true"]').prop('required',true);
        // END - Add required on input
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'o_nas': {
      init: function() {
        // JavaScript to be fired on the about us page
        // Clear top
        function sizing() {
            var clearTop = $('.box-overflow--long').height();
            if ($('.box-overflow').css('max-width') === '100%') {
                // do nothing
            } else {
                $('.block--home-quotes').css('margin-top', clearTop / 1.6);
            }
        }
        $(document).ready(sizing);
        $(window).resize(sizing);
        // END - Clear top
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
